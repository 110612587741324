import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/Layout';

const TosEulaPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>TOS / EULA - Great Intrigue LLC</title>
      </Helmet>

      <Header />
      <main id="main-content">
        <div className="container pt-5">
          <h1 className="h2">
            Great Intrigue LLC
            <br />
            Terms of Service and End User License Agreement (EULA)
          </h1>

          <hr />

          <p>
            <strong>Last Modified:</strong> March 18, 2022
          </p>

          <h2>Definition of Terms</h2>

          <p>The terms "We", "Our" and "Us", regardless of capitalization, herein refer to "Great Intrigue LLC".  The terms "You" and "Your" regardless of capitalization, refer to the reader of this Privacy Policy and/or any individuals or entities who use our services.  The term "services", regardless of capitalization, refers to any content or offerings created or provided by us, including, but not limited to, our websites and applications.</p>

          <p>The following Terms of Service and End User License Agreement EULA constitute an agreement between you and us. This EULA governs the use of our services.</p>

          <h2>Agreement to Terms of Service and EULA</h2>

          <p>By accessing or using our services, you agree to the terms outlined in this document.  Please check back periodically, as these Terms of Service and End User License Agreement may change over time, especially when our applications and websites are updated, or when we introduce new services and features.  We will notify you of any changes to our Terms of Service and End User License Agreement by posting the new Terms of Service and End User License Agreement on this page.  Changes are effective immediately upon being posted on this page.</p>

          <h2>Agreement to Privacy Policy</h2>

          <p>By using our services, you agree to the terms outlined in our Privacy Policy.  The most up to date version of our privacy policy is available at [www.greatintrigue.com/privacy].</p>

          <h2>Limited Use License</h2>

          <p>Upon accessing, downloading, installing, or using any of our services, you are granted a non-exclusive, non-transferable limited use license by us giving you permission to view and use our services in the manners intended by us.  This is the grant of a license, not a transfer of title, and under this license you may not modify, copy, reverse engineer, or redistribute our services or any information owned by us in any way.  Violation of this license is likely to infringe upon copyright laws or other property laws (intellectual or otherwise) unless the legal right to do so belongs to you.  In the event that you have violated any of the terms and conditions set forth in this document, this limited use license is automatically terminated by us.</p>

          <h2>Disclaimer of Warranties</h2>

          <p>Our services are provided "as-is" and "as-available".  Your use, reliance on, and/or dependence on our services is at your own risk.  We make no warranty that our services will meet your requirements, be accurate, be available, be timely, be uninterrupted, be free of defects or errors, or that any discovered defects or errors will be corrected.  We disclaim all warranties about our services to the fullest extent permitted by the law.</p>

          <h2>Limitation of Liability</h2>

          <p>Under no circumstances shall Great Intrigue LLC, our affiliates, or our partners be liable for any damages or losses including, but not limited to direct, indirect, consequential, or incidental damages or losses arising from or in connection with access or use of or inability to access or use our services, whether or not the damages or losses were foreseeable and whether or not we were advised of the possibility of such damages or losses.  Great Intrigue LLC's liability to you shall not exceed the amount of zero dollars ($0.00) under any circumstances.</p>
        </div>
      </main>
      <Footer />
    </Layout >
  )
};

export default TosEulaPage;
